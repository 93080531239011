footer{
    background-color: var(--background-color2);
    position: relative;
    width: 100%;
    bottom: 0 ;
    /* height: var(--footer-height); */
}

.footerContainer {
    display: flex;
    flex-direction: row;
    column-gap: 26px;
    padding: 16px;
}

.footerLeft {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    text-align: center;
}

.footerRight {
    display: flex;
    flex-direction: row;
    gap: 16px;
    text-align: left;
    align-items: center;
}

.footerLeft img{
    padding: 26px;
    padding-left: 0;
    margin-left: 0;
    width: 150px;
}

.contactInfo {
    text-align: left;
    row-gap: 8px;
    line-height: 24px;
    margin-right: 16px;
}

.contactInfo a{
    text-decoration: none;
}

.copyright {
    padding: 16px;
}

.copyright p{
    font-weight: 400;
    font-size: 24px;
    color: var(--text-color);
}

@media screen and (max-width: 490px){
    .footerContainer{
        display: flex;
        flex-direction: column;
    }
}
