.skillCard {
    text-align: center;
    text-wrap: wrap;
    padding: 16px;
    border-radius: 4px;
    flex: 1;
    row-gap: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--background-color);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
}

.skillCard ul{
    display:flex;
    flex-direction: column;
    /* row-gap: 4px; */
}


.cardsTitle {
    font-size: 1.2rem;
    font-weight: 700;
}