.projectContainer{
    display: flex;
    flex: row;
    flex-wrap: wrap;
    padding: 32px 16px;
}

.projectContainer img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 4px;
}


/* ////////////////////////////// */

.projectHolder {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  }
  
  .projectImageHolder {
    text-align: left;
    width: 90%; 
  }
  
  .projectImageHolder a {
    display: block;
    margin: 0 auto;
    width: fit-content;
    text-decoration: none;
  }
  
  .projectContent {
    position: relative;
    background-color: var(--primary);
    border: 1px solid var(--background-color2);
    border-radius: 10px;
    overflow: hidden;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .projectContent h3 {
    margin: 0;
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.7); 
    color: #fff;
    text-align: left;
  }
  
  .projectContent img {
    width: 100%;
    height: auto;
    display: block;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .projectImageHolder a:hover .projectContent {
    transform: scale(1.05);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  }
  
  .projectImageHolder a:hover img {
    transform: scale(1.05);
  }
  