header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    height: var(--header-height);
    background-color: var(--background-color2);
}

header img{
    width: var(--header-height);
    padding: 28px;
}

.headerContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 26px;
    flex: 1;
}

.headerContainer ul {
    font-weight: 700;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 26px;
}


.headerLanguage{

    position: fixed;
    right: 0;
    padding: 20px;
    visibility: hidden;
}


.placeHolder {
    height: var(--header-height);
    visibility: hidden;
}

ul a{
    cursor: pointer;
    text-decoration: none;
    line-height: 2;
    transition: 0.15s;
    border: 2px solid transparent;
}

ul a:hover{

    border-bottom: 2px solid;
}
