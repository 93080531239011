.aboutContainer {
    padding: 32px 16px;
    line-height: 24px;
}

.topContainer {
    display: grid;
    grid-template-columns: 0.4fr 1fr;
}

.leftContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 16px;
}

.leftContainer img {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
}

@media screen and (max-width: 490px){
    .topContainer{
        display: flex;
        flex-direction: column;
    }
}
